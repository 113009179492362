import get from 'lodash/fp/get';
import { batch } from 'react-redux';

import CreditCandidateSkillsSchema from '@careerstart/wae-common/schema/skills/credit-candidate/candidate.post.req.json';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';

import {
  creditCandidateSkills,
  creditCandidateSkillsProcessed,
  creditLoggedInCandidateSkills,
  creditLoggedInCandidateSkillsProcessed,
  emailVerificationOrPWResetUpdateUserData,
  loginProcessed as loginProcessedUpdateApp,
  showSnackbar,
  updateSkillsForSession,
  updateTraining,
  updateTrainingError,
  updateTrainingProcessed,
} from '../main/views/app';
import {
  addEmployeeToPlacement,
  addEmployeeToPlacementError,
  addEmployeeToPlacementProcessed,
  approveOrRejectPlacement,
  approveOrRejectPlacementError,
  approveOrRejectPlacementProcessed,
  cancelPlacement,
  cancelPlacementError,
  cancelPlacementProcessed,
  cancelSeriesJob,
  cancelSeriesJobError,
  cancelSeriesJobProcessed,
  cancelSingleJob,
  cancelSingleJobError,
  cancelSingleJobProcessed,
  getCancelledPlacement,
  getCancelledPlacementError,
  getCancelledPlacementProcessed,
  getJobOrderDetail,
  getJobOrderDetailError,
  getJobOrderDetailProcessed,
  getJobOrders,
  getJobOrdersError,
  getJobOrdersProcessed,
  inviteEmployee,
  inviteEmployeeError,
  inviteEmployeeProcessed,
  postGroupOrders,
  postGroupOrdersError,
  postGroupOrdersProcessed,
  postPlacementApproval,
  postPlacementApprovalError,
  postPlacementApprovalProcessed,
  postPlacementFinalize,
  postPlacementFinalizeError,
  postPlacementFinalizeProcessed,
  postPlacementResolve,
  postPlacementResolveError,
  postPlacementResolveProcessed,
  postPlacementUnFinalize,
  postPlacementUnFinalizeError,
  postPlacementUnFinalizeProcessed,
  removeUserFromGroup,
  removeUserFromGroupError,
  removeUserFromGroupProcessed,
  revokeJobInvitations,
  revokeJobInvitationsError,
  revokeJobInvitationsProcessed,
  sendInviteOrJobOffer,
  sendInviteOrJobOfferError,
  sendInviteOrJobOfferProcessed,
  updateCancelledPlacement,
  updateCancelledPlacementError,
  updateCancelledPlacementProcessed,
  updateExtensionGroup,
  updateExtensionGroupError,
  updateExtensionGroupProcessed,
  updateJobOrder,
  updateJobOrderError,
  updateJobOrderProcessed,
  updateJobRosterEmployee,
  updateJobRosterEmployeeError,
  updateJobRosterEmployeeProcessed,
  updatePlacementCheckInStatus,
  updatePlacementCheckInStatusError,
  updatePlacementCheckInStatusProcessed,
  updateTimeCard,
  updateTimeCardError,
  updateTimeCardProcessed,
} from '../main/views/jobOrders/jobOrdersReducer';
import { validateSchema } from '../main-deprecated/components/Form/validations';
import {
  getNotifications,
  getNotificationsError,
  getNotificationsProcessed,
  setNotificationsToRead,
  setNotificationsToReadError,
  setNotificationsToReadProcessed,
} from '../main-deprecated/components/NotificationCenter/reducer';
import { navigator } from '../main-deprecated/utils/navigator';
import { fileError, fileProcessed, getFile } from '../main-deprecated/views/app/fileReducer';
import {
  getCandidatesJobs,
  getCandidatesJobsError,
  getCandidatesJobsProcessed,
  getFeaturedJobs,
  getFeaturedJobsError,
  getFeaturedJobsProcessed,
  getIndustryJobs,
  getIndustryJobsError,
  getIndustryJobsProcessed,
  getInterestJobs,
  getInterestJobsError,
  getInterestJobsProcessed,
  getMyTimeThisWeek,
  getMyTimeThisWeekError,
  getMyTimeThisWeekProcessed,
  postPunchForDashboard,
  postPunchForDashboardError,
  postPunchForDashboardProcessed,
} from '../main-deprecated/views/Candidate/dashboard';
import {
  getInvitedJobOrder,
  getInvitedJobOrderError,
  getInvitedJobOrderProcessed,
  getSkills as getSkillsForJobInvite,
  getSkillsError as getSkillsForJobInviteError,
  getSkillsProcessed as getSkillsForJobInviteProcessed,
  postInviteRespond,
  postInviteRespondError,
  postInviteRespondProcessed,
} from '../main-deprecated/views/Candidate/jobInvitation';
import {
  cancelPlacementForCandidate,
  cancelPlacementForCandidateError,
  cancelPlacementForCandidateProcessed,
  createPlacementsForCandidate,
  createPlacementsForCandidateError,
  createPlacementsForCandidateProcessed,
  getCandidateDayJobsList,
  getCandidateDayJobsListError,
  getCandidateDayJobsListProcessed,
  getCandidateJobsList,
  getCandidateJobsListError,
  getCandidateJobsListProcessed,
  getCandidateLongJobsList,
  getCandidateLongJobsListError,
  getCandidateLongJobsListProcessed,
  getCandidatePathSelectedJob,
  getCandidatePathSelectedJobError,
  getCandidatePathSelectedJobProcessed,
  getCertificationsForCandidate,
  getCertificationsForCandidateError,
  getCertificationsForCandidateProcessed,
  getCorporationsForCandidate,
  getCorporationsForCandidateError,
  getCorporationsForCandidateProcessed,
  getFilesForCandidate,
  getFilesForCandidateError,
  getFilesForCandidateProcessed,
  getFilteredJobsList,
  getFilteredJobsListError,
  getFilteredJobsListProcessed,
  getGroupsJobOrders,
  getGroupsJobOrdersError,
  getGroupsJobOrdersProcessed,
  getJobOrderForCandidate,
  getJobOrderForCandidateError,
  getJobOrderForCandidateProcessed,
  getPathSelectedPlacementForCandidate,
  getPathSelectedPlacementForCandidateError,
  getPathSelectedPlacementForCandidateProcessed,
  getPlacements,
  getPlacementsError,
  getPlacementsForCandidate,
  getPlacementsForCandidateError,
  getPlacementsForCandidateProcessed,
  getPlacementsProcessed,
  getSkills as getSkillsForJobSignUp,
  getSkillsError as getSkillsForJobSignUpError,
  getSkillsProcessed as getSkillsForJobSignUpProcessed,
  rescindPlacementForCandidate,
  rescindPlacementForCandidateError,
  rescindPlacementForCandidateProcessed,
} from '../main-deprecated/views/Candidate/jobs';
import {
  getNextShift,
  getNextShiftError,
  getNextShiftProcessed,
  getTimeCards as getEmployeeTimeCards,
  getTimeCardsError as getEmployeeTimeCardsError,
  getTimeCardsProcessed as getEmployeeTimeCardsProcessed,
  postDisputeTimeCard,
  postDisputeTimeCardError,
  postDisputeTimeCardProcessed,
  postPunch as postPunchForTimeTracking,
  postPunchError as postPunchForTimeTrackingError,
  postPunchProcessed as postPunchForTimeTrackingProcessed,
} from '../main-deprecated/views/Candidate/timeTracking';
import {
  createDepartment,
  createDepartmentError,
  createDepartmentProcessed,
  deleteDepartment,
  deleteDepartmentError,
  deleteDepartmentProcessed,
  editDepartment,
  editDepartmentError,
  editDepartmentProcessed,
  getCorporationsList,
  getCorporationsListError,
  getCorporationsListProcessed,
  getDepartments,
  getDepartmentsError,
  getDepartmentsProcessed,
} from '../main-deprecated/views/corporations/corporationList';
import {
  postCorporation,
  postCorporationError,
  postCorporationProcessed,
} from '../main-deprecated/views/corporations/createCorporation';
import {
  postForgotPassword,
  postForgotPasswordError,
  postForgotPasswordProcessed,
} from '../main-deprecated/views/forgotMyPassword';
import {
  getCorporations as getCorporationsForJobsOrder,
  getCorporationsError as getCorporationsForJobsOrderError,
  getCorporationsProcessed as getCorporationsProcessedForJobsOrder,
  getPositionTemplate as getPositionTemplateForJobsOrder,
  getPositionTemplateErrorForJobs as getPositionTemplateErrorForJobsOrder,
  getPositionTemplateProcessedForJobs as getPositionTemplateProcessedForJobsOrder,
  postJobs,
  postJobsError,
  postJobsProcessed,
} from '../main-deprecated/views/jobs/createJobs';
import {
  cancelShiftGroup,
  cancelShiftGroupError,
  cancelShiftGroupProcessed,
  getCorporationsForJobsList,
  getCorporationsForJobsListError,
  getCorporationsForJobsListProcessed,
  getGroupOrders,
  getGroupOrdersError,
  getGroupOrdersProcessed,
  getJobRoster,
  getJobRosterError,
  getJobRosterProcessed,
  getPathSelectedJob,
  getPathSelectedJobError,
  getPathSelectedJobProcessed,
  getPositionTemplate as getPositionTemplateForJobsList,
  getPositionTemplateError as getPositionTemplateErrorForJobsList,
  getPositionTemplateProcessed as getPositionTemplateProcessedForJobsList,
  getShiftGroupCancelledCount,
  getShiftGroupCancelledCountError,
  getShiftGroupCancelledCountProcessed,
  getShiftGroupList,
  getShiftGroupListError,
  getShiftGroupListProcessed,
  postTimecardApprove as postTimecardApproveForJobList,
  postTimecardApproveError as postTimecardApproveForJobListError,
  postTimecardApproveProcessed as postTimecardApproveForJobListProcessed,
  postTimecardResolve as postTimecardResolveForJobList,
  postTimecardResolveError as postTimecardResolveForJobListError,
  postTimecardResolveProcessed as postTimecardResolveForJobListProcessed,
  setIsCreateJobsDialogOpen,
  updateCancelledPlacement as updateCancelledPlacementDeprecated,
  updateCancelledPlacementError as updateCancelledPlacementErrorDeprecated,
  updateCancelledPlacementProcessed as updateCancelledPlacementProcessedDeprecated,
  updatePlacementCheckIn,
  updatePlacementCheckInError,
  updatePlacementCheckInProcessed,
} from '../main-deprecated/views/jobs/jobList';
import {
  login as loginUpdateLogin,
  loginError,
  loginProcessed,
} from '../main-deprecated/views/login';
import {
  updatePassword,
  updatePasswordError,
  updatePasswordProcessed,
} from '../main-deprecated/views/passwordUpdate';
import {
  disableSendVerificationEmailButton,
  passwordReset,
  passwordResetError,
  passwordResetProcessed,
  reqVerifyAccount,
  verifyAccount,
  verifyAccountError,
  verifyAccountProcessed,
} from '../main-deprecated/views/PasswordVerificationOrReset';
import {
  postPositionTemplate,
  postPositionTemplateError,
  postPositionTemplateProcessed,
} from '../main-deprecated/views/positionTemplate/createPositionTemplateDrawer';
import {
  deletePositionTemplate,
  deletePositionTemplateError,
  deletePositionTemplateProcessed,
  getCertificationsForPositionTemplateList,
  getCertificationsForPositionTemplateListError,
  getCertificationsForPositionTemplateListProcessed,
  getCorporationsForPositionTemplateList,
  getCorporationsForPositionTemplateListError,
  getCorporationsForPositionTemplateListProcessed,
  getLocationsForPositionTemplateList,
  getLocationsForPositionTemplateListError,
  getLocationsForPositionTemplateListProcessed,
  getPositionTemplateList,
  getPositionTemplateListError,
  getPositionTemplateListProcessed,
  updatePositionTemplate,
  updatePositionTemplateError,
  updatePositionTemplateProcessed,
} from '../main-deprecated/views/positionTemplate/positionTemplateList';
import {
  registerCandidate,
  registerCandidateError,
  registerCandidateProcessed,
  registerEmployer,
  registerEmployerError,
  registerEmployerProcessed,
} from '../main-deprecated/views/register/reducer';
import {
  getDnas,
  getDnasError,
  getDnasProcessed,
  getDnrs,
  getDnrsError,
  getDnrsProcessed,
  getDoNotReturnBreakdown,
  getDoNotReturnBreakdownError,
  getDoNotReturnBreakdownProcessed,
  getEmployeeRetention,
  getEmployeeRetentionError,
  getEmployeeRetentionProcessed,
  getEmployeesHired,
  getEmployeesHiredError,
  getEmployeesHiredProcessed,
  getFillRatio,
  getFillRatioError,
  getFillRatioProcessed,
  getJobOrderRevenue,
  getJobOrderRevenueError,
  getJobOrderRevenueProcessed,
  getJobsCompleted,
  getJobsCompletedError,
  getJobsCompletedProcessed,
  getJobsEntered,
  getJobsEnteredError,
  getJobsEnteredProcessed,
  getOverallAttendance,
  getOverallAttendanceError,
  getOverallAttendanceProcessed,
  getTimeBasedJobOrderNumbers,
  getTimeBasedJobOrderNumbersError,
  getTimeBasedJobOrderNumbersProcessed,
  getUserCountByRole,
  getUserCountByRoleError,
  getUserCountByRoleProcessed,
} from '../main-deprecated/views/reporting';
import {
  getCertificationsForSettings,
  getCertificationsForSettingsError,
  getCertificationsForSettingsProcessed,
  getUserInfo,
  getUserInfoError,
  getUserInfoProcessed,
  updateSkillsForSettings,
  updateSkillsForSettingsError,
  updateSkillsForSettingsProcessed,
} from '../main-deprecated/views/settings';
import {
  deleteCert,
  deleteCertError,
  deleteCertProcessed,
  deleteSkill,
  deleteSkillError,
  deleteSkillProcessed,
  getCerts,
  getCertsError,
  getCertsProcessed,
  getSkills,
  getSkillsError,
  getSkillsProcessed,
  sendNewSkillToBackend,
  sendNewSkillToBackendError,
  sendNewSkillToBackendProcessed,
} from '../main-deprecated/views/skillsAndCerts';
import {
  getPathSelectedTimeCards,
  getPathSelectedTimeCardsError,
  getPathSelectedTimeCardsProcessed,
  getTimeCards,
  getTimeCardsError,
  getTimeCardsProcessed,
  postTimeCardApproval,
  postTimeCardApprovalError,
  postTimeCardApprovalProcessed,
  postTimeCardBatchApproval,
  postTimeCardBatchApprovalError,
  postTimeCardBatchApprovalProcessed,
  postTimeCardFinalize,
  postTimeCardFinalizeError,
  postTimeCardFinalizeProcessed,
  postTimeCardResolve,
  postTimeCardResolveError,
  postTimeCardResolveProcessed,
  postTimeCardUnFinalize,
  postTimeCardUnFinalizeError,
  postTimeCardUnFinalizeProcessed,
  updateTimeCard as updateTimeCardDeprecated,
  updateTimeCardError as updateTimeCardDeprecatedError,
  updateTimeCardProcessed as updateTimeCardDeprecatedProcessed,
} from '../main-deprecated/views/timekeeping';
import {
  deactivateUser,
  deactivateUserError,
  deactivateUserProcessed,
  getCertificationsForUsers,
  getCertificationsForUsersError,
  getCertificationsForUsersProcessed,
  getCorporations as getCorporationsForUsers,
  getCorporationsError as getCorporationsErrorForUsers,
  getCorporationsProcessed as getCorporationsProcessedForUsers,
  getUserHistory,
  getUserHistoryError,
  getUserHistoryProcessed,
  getUsers,
  getUsersError,
  getUsersProcessed,
  postBullhornEmployers,
  postBullhornEmployersError,
  postBullhornEmployersProcessed,
  postUser,
  postUserError,
  postUserProcessed,
  updateCandidateDetails,
  updateCandidateDetailsError,
  updateCandidateDetailsProcessed,
  updateCertifications,
  updateCertificationsError,
  updateCertificationsProcessed,
  updateDNA,
  updateDNAError,
  updateDNAProcessed,
  updateDNR,
  updateDNRError,
  updateDNRProcessed,
  updateSkills,
  updateSkillsError,
  updateSkillsProcessed,
  updateUser,
  updateUserError,
  updateUserProcessed,
} from '../main-deprecated/views/users/reducer';

export const dispatchErrors = ({ dispatch, errorHandler, responsePayload, customMessage }) => {
  const errors = get(['data', 'errors'], responsePayload);
  dispatch && errorHandler && dispatch(errorHandler({ errors }));
  const globalError = get('GLOBAL', errors);
  if (dispatch) {
    if (globalError && globalError.messageKey) {
      dispatch(showSnackbar({ message: globalError.messageKey }));
    } else {
      dispatch(showSnackbar({ message: customMessage || 'error.generic' }));
    }
  }
};

const standardErrorHandler = (payload, dispatch) => {
  dispatchErrors({ dispatch, responsePayload: payload });
};

const API = [
  {
    http: 'post',
    path: 'users/login',
    type: loginUpdateLogin.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(loginProcessedUpdateApp(payload));
        dispatch(loginProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: loginError, responsePayload: payload });
    },
  },
  {
    http: 'patch',
    path: 'users/training',
    type: updateTraining.type,
    successCallback: (payload, dispatch) => {
      dispatch(updateTrainingProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updateTrainingError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'skills/credit-candidates',
    type: creditLoggedInCandidateSkills.type,
    successCallback: (payload, dispatch) => {
      dispatch(creditLoggedInCandidateSkillsProcessed(payload));
    },
    errorCallback: standardErrorHandler,
  },
  {
    http: 'post',
    path: 'skills/credit-candidates',
    type: creditCandidateSkills.type,
    successCallback: (payload, dispatch) => {
      dispatch(
        showSnackbar({ message: 'The skills for this job have been saved to the candidate' })
      );
      dispatch(creditCandidateSkillsProcessed(payload));
    },
    errorCallback: standardErrorHandler,
  },

  {
    http: 'post',
    path: 'users/register-candidate',
    type: registerCandidate.type,
    successCallback: (payload, dispatch) => {
      navigator('/');
      const registerAction = registerCandidateProcessed(payload);
      const message = 'Verify email address to continue; verification email has been sent.';
      const messageAction = showSnackbar({ message });
      batch(() => {
        dispatch(registerAction);
        dispatch(messageAction);
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: registerCandidateError, responsePayload: payload });
    },
  },
  {
    http: 'post',
    path: 'users/register-employer',
    type: registerEmployer.type,
    successCallback: (payload, dispatch) => {
      navigator('/');
      const registerAction = registerEmployerProcessed(payload);
      const message = 'Verify email address to continue; verification email has been sent.';
      const messageAction = showSnackbar({ message });
      batch(() => {
        dispatch(registerAction);
        dispatch(messageAction);
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: registerEmployerError, responsePayload: payload });
    },
  },

  {
    http: 'post',
    path: 'users/summary',
    type: getUserHistory.type,
    successCallback: (payload, dispatch) => {
      dispatch(getUserHistoryProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: getUserHistoryError, responsePayload: payload });
    },
  },

  {
    http: 'patch',
    path: 'password',
    type: updatePassword.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        navigator('dashboard');
        dispatch(updatePasswordProcessed(payload));
        dispatch(showSnackbar({ message: 'Password update successful!' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updatePasswordError,
        responsePayload: payload,
        customMessage: get(['data', 'errors', 'newPassword'], payload) || 'error.password.update',
      });
    },
  },
  // TODO:Update Function on Corporation DNR/Favorite Tab
  // {
  //   http: 'patch',
  //   path: 'corporations',
  //   type: updateFavoriteDnr.type,
  //   successCallback: (payload, dispatch) => {
  //     batch(() => {
  //       // dispatch(updateFavoriteDnr(payload));
  //     });
  //   },
  //   errorCallback: (payload, dispatch) => {
  //     const errors = get(['data', 'errors'], payload);
  //     // dispatch(updateFavoriteDnr(errors));
  //
  //     const globalError = get(['data', 'errors', 'GLOBAL'], payload);
  //     globalError &&
  //       globalError.messageKey &&
  //       dispatch(showSnackbar({ message: message || globalError.messageKey }));
  //   },
  // },

  {
    http: 'post', // Create Job Order
    path: 'job-orders/batch',
    type: postJobs.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postJobsProcessed(payload));
        dispatch(showSnackbar({ message: 'Job Post Successful!' }));
        dispatch(setIsCreateJobsDialogOpen(false));
        navigator('jobs/jobslist');
      });
    },
    errorCallback: (payload, dispatch) => {
      const localErrors = get(['data', 'errors'], payload);
      const startErrorKey = Object.keys(localErrors).find((k) => k.endsWith('start'));
      dispatchErrors({
        dispatch,
        errorHandler: postJobsError,
        customMessage: !!startErrorKey && localErrors[startErrorKey],
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post', // Get List of Job Order
    path: 'job-orders/read',
    type: getJobOrders.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobOrdersProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: getJobOrdersError, responsePayload: payload });
    },
  },

  {
    http: 'post', // Gets Detail of Job Order
    path: 'views/job-orders/details/read',
    type: getJobOrderDetail.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobOrderDetailProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: getJobOrderDetailError, responsePayload: payload });
    },
  },
  {
    http: 'patch', // update extensionGroup
    path: 'job-orders/extension-group',
    type: updateExtensionGroup.type,
    successCallback: (payload, dispatch) => {
      dispatch(updateExtensionGroupProcessed(payload));
      dispatch(showSnackbar({ message: 'jobDetails.extensionUpdate.success' }));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updateExtensionGroupError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'job-orders/read',
    type: getPathSelectedJob.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPathSelectedJobProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: getPathSelectedJobError, responsePayload: payload });
    },
  },

  {
    http: 'post',
    path: 'job-orders/groups/shift/read',
    type: getGroupOrders.type,
    successCallback: (payload, dispatch) => {
      dispatch(getGroupOrdersProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: getGroupOrdersError, responsePayload: payload });
    },
  },
  {
    http: 'post',
    path: 'job-orders/read',
    type: getShiftGroupList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getShiftGroupListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: getShiftGroupListError, responsePayload: payload });
    },
  },
  {
    http: 'post',
    path: 'job-orders/read',
    type: getShiftGroupCancelledCount.type,
    successCallback: (payload, dispatch) => {
      dispatch(getShiftGroupCancelledCountProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getShiftGroupCancelledCountError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post', // post create-job
    path: 'create-job-order',
    type: postGroupOrders.type,
    successCallback: (payload, dispatch) => {
      dispatch(postGroupOrdersProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: postGroupOrdersError, responsePayload: payload });
    },
  },
  {
    http: 'post', // Get invited job
    path: 'job-orders/read',
    type: getInvitedJobOrder.type,
    successCallback: (payload, dispatch) => {
      dispatch(getInvitedJobOrderProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: getInvitedJobOrderError, responsePayload: payload });
    },
  },
  {
    http: 'post', // Get List of Position Templates
    path: 'position-templates/read',
    type: getPositionTemplateList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPositionTemplateListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getPositionTemplateListError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post', // Get List of Users
    path: 'users/read',
    type: getUsers.type,
    successCallback: (payload, dispatch) => {
      dispatch(getUsersProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: getUsersError, responsePayload: payload });
    },
  },

  {
    http: 'post', // Get myJobs list for candidate
    path: 'placements/read',
    type: getPlacementsForCandidate.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPlacementsForCandidateProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getPlacementsForCandidateError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post', // Get myJobs list for candidate
    path: 'placements/read',
    type: getPathSelectedPlacementForCandidate.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPathSelectedPlacementForCandidateProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getPathSelectedPlacementForCandidateError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'placements/read',
    type: getCancelledPlacement.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCancelledPlacementProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCancelledPlacementError,
        responsePayload: payload,
      });
    },
  },
  /*
  The code below sends a verification email to a user and handles success and error callbacks.

  On success:
  - The email verification process is considered as processed.
  - A snackbar is shown with the message 'Verification email sent'.
  - The 'Send Verification Email' button is temporarily disabled for 5 minutes

  On error:
  - Verification email request error is dispatched.
  - A snackbar is shown with an error message based on the error type.
  - The 'Send Verification Email' button may be temporarily disabled based on the errors.

  The requestCodeBlock object is defined as follows:
*/
  {
    http: 'post', // Send verification email to user using HTTP POST method
    path: 'users/request-verification', // API endpoint
    type: reqVerifyAccount.type,
    successCallback: (payload, dispatch) => {
      // On a successful verification email request
      batch(() => {
        dispatch(verifyAccountProcessed(payload)); // Dispatch account verification processed
        dispatch(showSnackbar({ message: 'Verification email sent' })); // Show snackbar message
        dispatch(disableSendVerificationEmailButton(true));
      });
      setTimeout(() => {
        dispatch(disableSendVerificationEmailButton(false));
      }, 300000);
    },
    errorCallback: (payload, dispatch) => {
      const diffTime =
        get(['data', 'errors', 'verificationResendPreventedUntil'], payload) - Date.now();
      dispatchErrors({
        dispatch,
        errorHandler: verifyAccountError,
        responsePayload: payload,
      });
      setTimeout(() => {
        dispatch(disableSendVerificationEmailButton(false));
      }, diffTime);
    },
  },

  {
    http: 'get', // Get singular Job Order for Employee
    path: 'job-orders',
    type: getJobOrderForCandidate.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobOrderForCandidateProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getJobOrderForCandidateError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'patch',
    path: 'job-orders/cancel',
    type: cancelSingleJob.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(cancelSingleJobProcessed(payload));
        dispatch(showSnackbar({ message: 'jobDetails.cancelSuccess' }));
        navigator('/jobs/jobsList');
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: cancelSingleJobError, responsePayload: payload });
    },
  },
  {
    http: 'patch',
    path: 'job-orders/extension-group/cancel-group',
    type: cancelSeriesJob.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(cancelSeriesJobProcessed(payload));
        dispatch(showSnackbar({ message: 'jobDetails.cancelSuccess' }));
        navigator('/jobs/jobsList');
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: cancelSeriesJobError, responsePayload: payload });
    },
  },
  {
    http: 'patch',
    path: 'job-orders/groups/shift/cancel',
    type: cancelShiftGroup.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(cancelShiftGroupProcessed(payload));
        dispatch(showSnackbar({ message: 'Cancel Job Successful!' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      // FIXME: HB-114 need better error messages
      dispatchErrors({ dispatch, errorHandler: cancelShiftGroupError, responsePayload: payload });
    },
  },

  {
    http: 'patch',
    path: 'job-orders',
    type: updateJobOrder.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateJobOrderProcessed(payload));
        dispatch(showSnackbar({ message: 'Job update successful!' })); // FIXME: HB-114 need better error messages
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: updateJobOrderError, responsePayload: payload });
    },
  },
  {
    http: 'patch',
    path: 'users',
    type: updateUser.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateUserProcessed(payload));
        dispatch(getUsers());
        dispatch(showSnackbar({ message: 'User update successful!' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: updateUserError, responsePayload: payload });
    },
  },
  {
    http: 'post',
    path: 'users/deactivate',
    type: deactivateUser.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(deactivateUserProcessed(payload));
        dispatch(showSnackbar({ message: 'success.user.deactivate' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({ dispatch, errorHandler: deactivateUserError, responsePayload: payload });
    },
  },

  {
    http: 'get',
    path: 'corporations',
    type: getCorporationsForJobsList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCorporationsForJobsListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCorporationsForJobsListError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'position-templates',
    type: postPositionTemplate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postPositionTemplateProcessed(payload));
        dispatch(showSnackbar({ message: 'success.position.template.post' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postPositionTemplateError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'get',
    path: 'position-templates',
    type: getPositionTemplateForJobsList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPositionTemplateProcessedForJobsList(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getPositionTemplateErrorForJobsList,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'get',
    path: 'corporations',
    type: getCorporationsForUsers.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCorporationsProcessedForUsers(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCorporationsErrorForUsers,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'get',
    path: 'corporations',
    type: getCorporationsForJobsOrder.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCorporationsProcessedForJobsOrder(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCorporationsForJobsOrderError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'corporations',
    type: getCorporationsForCandidate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getCorporationsForCandidateProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCorporationsForCandidateError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'notifications/read',
    type: getNotifications.type,
    successCallback: (payload, dispatch) => {
      dispatch(getNotificationsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getNotificationsError,
        responsePayload: payload,
        customMessage: 'error.read.notifications',
      });
    },
  },
  {
    http: 'patch',
    path: 'notifications/mark-read',
    type: setNotificationsToRead.type,
    successCallback: (payload, dispatch) => {
      dispatch(setNotificationsToReadProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: setNotificationsToReadError,
        responsePayload: payload,
        customMessage: 'error.markRead.notifications',
      });
    },
  },
  {
    http: 'post',
    path: 'corporations/read',
    type: getCorporationsList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCorporationsListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCorporationsListError,
        responsePayload: payload,
      });
    },
  },
  {
    axiosOptions: {
      responseType: 'blob',
    },
    http: 'get',
    path: 'files',
    type: getFilesForCandidate.type,
    successCallback: (payload, dispatch) => {
      dispatch(getFilesForCandidateProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getFilesForCandidateError,
        responsePayload: payload,
      });
    },
  },
  {
    axiosOptions: {
      responseType: 'blob',
    },
    http: 'get',
    path: 'files',
    type: getFile.type,
    successCallback: (payload, dispatch) => {
      dispatch(fileProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: fileError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'position-templates/read',
    type: getPositionTemplateForJobsOrder.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPositionTemplateProcessedForJobsOrder(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getPositionTemplateErrorForJobsOrder,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'delete',
    path: 'position-templates',
    type: deletePositionTemplate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(deletePositionTemplateProcessed(payload));
        dispatch(showSnackbar({ message: 'success.position.template.delete' }));
        dispatch(getPositionTemplateList());
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: deletePositionTemplateError,
        responsePayload: payload,
        customMessage: 'error.positionTemplate.failDelete',
      });
    },
  },

  {
    http: 'patch',
    path: 'position-templates',
    type: updatePositionTemplate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updatePositionTemplateProcessed(payload));
        dispatch(showSnackbar({ message: 'Position updated successfully!' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updatePositionTemplateError,
        responsePayload: payload,
        customMessage: 'error.positionTemplate.failUpdate',
      });
    },
  },

  {
    http: 'get', // Get List of Positions
    path: 'position-templates',
    type: getPositionTemplateList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getPositionTemplateListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getPositionTemplateListError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'locations/read',
    type: getLocationsForPositionTemplateList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getLocationsForPositionTemplateListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getLocationsForPositionTemplateListError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'get',
    path: 'certifications',
    type: getCertificationsForSettings.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCertificationsForSettingsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCertificationsForSettingsError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'skills/update-candidates',
    type: updateSkillsForSettings.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateSkillsForSettingsProcessed(payload));
        dispatch(updateSkillsForSession(payload));
        dispatch(showSnackbar({ message: 'candidate.skillUpdated.successfully' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updateSkillsForSettingsError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'users/read',
    type: getUserInfo.type,
    successCallback: (payload, dispatch) => {
      dispatch(getUserInfoProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getUserInfoError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'get',
    path: 'certifications',
    type: getCertificationsForPositionTemplateList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCertificationsForPositionTemplateListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCertificationsForPositionTemplateListError,
        responsePayload: payload,
      });
    },
  },
  // user list
  {
    http: 'get',
    path: 'certifications',
    type: getCertificationsForUsers.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCertificationsForUsersProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCertificationsForUsersError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'get',
    path: 'corporations',
    type: getCorporationsForPositionTemplateList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCorporationsForPositionTemplateListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCorporationsForPositionTemplateListError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'bullhorn/corporations',
    type: postCorporation.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postCorporationProcessed(payload));

        dispatch(showSnackbar({ message: 'success.corporation.create' }));
        navigator('/corporations/corporationList');
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postCorporationError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'users',
    type: postUser.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postUserProcessed(payload));
        dispatch(showSnackbar({ message: 'User created successfully!' })); // TODO: HB-200 Should not be hardcoded for multiple languages
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postUserError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'bullhorn/employers',
    type: postBullhornEmployers.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postBullhornEmployersProcessed(payload));
        dispatch(showSnackbar({ message: 'User created successfully!' })); // TODO: HB-200 Should not be hardcoded for multiple languages
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postBullhornEmployersError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'users',
    type: postUser.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postUserProcessed(payload));
        dispatch(showSnackbar({ message: 'User created successfully!' })); // TODO: HB-200 Should not be hardcoded for multiple languages
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postUserError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'users/verify',
    type: verifyAccount.type,
    successCallback: (payload, dispatch) => {
      const snackBarMessage = 'account.verified.successfully';
      batch(() => {
        if (payload?.data?.role === UserRole.CANDIDATE) {
          navigator('/employee-mobile-app-info');
        } else {
          navigator('/dashboard');
          dispatch(emailVerificationOrPWResetUpdateUserData(payload));
        }
        dispatch(verifyAccountProcessed(payload));
        dispatch(showSnackbar({ message: snackBarMessage }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: verifyAccountError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'password/reset',
    type: passwordReset.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        navigator('/dashboard');
        dispatch(passwordResetProcessed(payload));
        dispatch(emailVerificationOrPWResetUpdateUserData(payload));
        dispatch(showSnackbar({ message: 'Account Password Reset' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: passwordResetError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'password/request-reset',
    type: postForgotPassword.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        navigator('/');
        dispatch(postForgotPasswordProcessed(payload));
        dispatch(
          showSnackbar({ message: 'Email sent. Please check your email to reset your password.' }) // FIXME: HB-943 Change email to either email or mobile once mobile option is available
        );
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postForgotPasswordError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post', // Get List of job group template for candidates
    path: 'workable-groups',
    type: getCandidateJobsList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCandidateJobsListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCandidateJobsListError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post', // Get List of job group template for candidates
    path: 'workable-groups',
    type: getCandidatePathSelectedJob.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCandidatePathSelectedJobProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCandidatePathSelectedJobError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post', // Get filtered List of job group template for candidates
    path: 'workable-groups',
    type: getFilteredJobsList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getFilteredJobsListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getFilteredJobsListError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'day-jobs',
    type: getCandidateDayJobsList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCandidateDayJobsListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCandidateDayJobsListError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'long-term-jobs',
    type: getCandidateLongJobsList.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCandidateLongJobsListProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCandidateLongJobsListError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'get',
    path: 'certifications',
    type: getCertificationsForCandidate.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCertificationsForCandidateProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCertificationsForCandidateError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'placements',
    type: createPlacementsForCandidate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(showSnackbar({ message: 'Job Scheduled!' }));
        dispatch(createPlacementsForCandidateProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: createPlacementsForCandidateError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'placements',
    type: getPlacements.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getPlacementsProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getPlacementsError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'job-orders/read',
    type: getGroupsJobOrders.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getGroupsJobOrdersProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getGroupsJobOrdersError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'patch',
    path: 'placements/cancel',
    type: cancelPlacementForCandidate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(cancelPlacementForCandidateProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: cancelPlacementForCandidateError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'placement/application/rescind',
    type: rescindPlacementForCandidate.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(rescindPlacementForCandidateProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: rescindPlacementForCandidateError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'timecard/punch',
    type: postPunchForDashboard.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        const message = 'success.punch';
        dispatch(showSnackbar({ message }));
        dispatch(postPunchForDashboardProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postPunchForDashboardError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'timecard/punch',
    type: postPunchForTimeTracking.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        const message = 'success.punch';
        dispatch(showSnackbar({ message }));
        dispatch(postPunchForTimeTrackingProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postPunchForTimeTrackingError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'patch', // user detail update certifications
    path: 'users/update-certifications',
    type: updateCertifications.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateCertificationsProcessed(payload));
        dispatch(showSnackbar({ message: 'Certifications updated successful!' }));
      });
      location.reload(); // eslint-disable-line
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updateCertificationsError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'skills/update-candidates',
    type: updateSkills.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateSkillsProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updateSkillsError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'patch', // user detail update distance,industry and certs
    path: 'users',
    type: updateCandidateDetails.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateCandidateDetailsProcessed(payload));
        dispatch(showSnackbar({ message: 'Candidate updated successful!' }));
        location.reload(); // eslint-disable-line
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updateCandidateDetailsError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'placements',
    type: addEmployeeToPlacement.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        const message = 'candidate.assigned.successfully';
        dispatch(showSnackbar({ message }));
        dispatch(addEmployeeToPlacementProcessed(payload));
        const docs = get(['data', 'documents'], payload);
        const candidate = docs[0]?.candidate;
        const candidateSkills = (candidate?.candidate?.skills || []).map((skill) => skill.skill);
        const skillsToCredit = [];
        (docs[0]?.jobOrder?.skills || []).forEach((skill) => {
          if (!candidateSkills.includes(skill)) {
            skillsToCredit.push(skill);
          }
        });
        if (skillsToCredit.length !== 0) {
          const req = {
            candidates: [candidate._id],
            skills: skillsToCredit,
          };
          const schemaErrors = validateSchema(req, CreditCandidateSkillsSchema);
          if (Object.keys(schemaErrors).length === 0) {
            dispatch(creditCandidateSkills(req));
          }
        }
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: addEmployeeToPlacementError,
        responsePayload: payload,
        customMessage: 'error.placement.update',
      });
    },
  },

  {
    http: 'patch',
    path: 'placements/cancel',
    type: cancelPlacement.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        const message = 'jobDetails.placement.cancelled.success';
        dispatch(showSnackbar({ message }));
        dispatch(cancelPlacementProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: cancelPlacementError,
        responsePayload: payload,
        customMessage: 'error.placement.cancel',
      });
    },
  },

  {
    http: 'post',
    path: 'job-orders/extension-group/remove-candidate',
    type: removeUserFromGroup.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        const message = 'jobDetails.placements.cancelled.success';
        dispatch(showSnackbar({ message }));
        dispatch(removeUserFromGroupProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: removeUserFromGroupError,
        responsePayload: payload,
        customMessage: 'error.placement.cancel',
      });
    },
  },

  {
    http: 'post', // Get  Job Placements for Job Order
    path: 'placements/read-for-job-order',
    type: getJobRoster.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobRosterProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getJobRosterError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post', // placements/approve
    path: 'placements/approve',
    type: approveOrRejectPlacement.type,
    successCallback: (payload, dispatch) => {
      dispatch(approveOrRejectPlacementProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: approveOrRejectPlacementError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'job-orders/invite/respond',
    type: revokeJobInvitations.type,
    successCallback: (payload, dispatch) => {
      dispatch(revokeJobInvitationsProcessed(payload));
      dispatch(showSnackbar({ message: 'success.placement.rejected' }));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: revokeJobInvitationsError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'patch', // Update Job Placements for Job Order
    path: 'placements',
    type: updateJobRosterEmployee.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateJobRosterEmployeeProcessed(payload));
        dispatch(showSnackbar({ message: 'success.jobRoster.update' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updateJobRosterEmployeeError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post', // Update placement check in or out
    path: 'placement/check-in',
    type: updatePlacementCheckIn.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updatePlacementCheckInProcessed(payload));
        dispatch(showSnackbar({ message: 'success.placement.check.inOut.update' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updatePlacementCheckInError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'patch', // Update Cancelled Placement
    path: 'placements',
    type: updateCancelledPlacementDeprecated.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateCancelledPlacementProcessedDeprecated(payload));
        dispatch(showSnackbar({ message: 'success.cancelledPlacement.update' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updateCancelledPlacementErrorDeprecated,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'patch', // Update Cancelled Placement
    path: 'placements',
    type: updateCancelledPlacement.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateCancelledPlacementProcessed(payload));
        dispatch(showSnackbar({ message: 'success.cancelledPlacement.update' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updateCancelledPlacementError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'patch', // Update DNA
    path: 'users/dna',
    type: updateDNA.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateDNAProcessed(payload));
        dispatch(showSnackbar({ message: 'success.dna.update' }));
        // dispatch(getUserHistory(query id));// if we have query id, we can use it to update the user new DNA/NDR data to void refresh
      });
      location.reload(); // eslint-disable-line
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updateDNAError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'patch', // Update DNR
    path: 'corporations/dnr',
    type: updateDNR.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateDNRProcessed(payload));
        dispatch(showSnackbar({ message: 'success.dnr.update' }));
        // dispatch(getUserHistory(query id));// if we have query id, we can use it to update the user new DNA/NDR data to void refresh
      });
      location.reload(); // eslint-disable-line
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updateDNRError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'timecards/read',
    type: getTimeCards.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getTimeCardsProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getTimeCardsError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'timecards/read',
    type: getPathSelectedTimeCards.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(getPathSelectedTimeCardsProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getPathSelectedTimeCardsError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'patch',
    path: 'timecard/update',
    type: updateTimeCardDeprecated.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateTimeCardDeprecatedProcessed(payload));
        dispatch(showSnackbar({ message: 'success.timecard.update' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updateTimeCardDeprecatedError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'patch',
    path: 'timecard/update',
    type: updateTimeCard.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updateTimeCardProcessed(payload));
        dispatch(showSnackbar({ message: 'success.timecard.update' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updateTimeCardError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'timecards/read',
    type: getEmployeeTimeCards.type,
    successCallback: (payload, dispatch) => {
      dispatch(getEmployeeTimeCardsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getEmployeeTimeCardsError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'timecard/dispute',
    type: postDisputeTimeCard.type,
    successCallback: (payload, dispatch) => {
      dispatch(postDisputeTimeCardProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postDisputeTimeCardError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'get-next-engagements',
    type: getNextShift.type,
    successCallback: (payload, dispatch) => {
      dispatch(getNextShiftProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getNextShiftError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'timecard/approve',
    type: postTimeCardApproval.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postTimeCardApprovalProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postTimeCardApprovalError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'timecard/approve',
    type: postTimeCardBatchApproval.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postTimeCardBatchApprovalProcessed(payload));
        dispatch(showSnackbar({ message: 'success.timecard.batch.approval' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postTimeCardBatchApprovalError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'timecard/approve',
    type: postTimecardApproveForJobList.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postTimecardApproveForJobListProcessed(payload));
        dispatch(showSnackbar({ message: 'success.placement.approve' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postTimecardApproveForJobListError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'timecard/resolve',
    type: postTimeCardResolve.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postTimeCardResolveProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postTimeCardResolveError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'timecard/resolve',
    type: postTimecardResolveForJobList.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postTimecardResolveForJobListProcessed(payload));
        dispatch(showSnackbar({ message: 'success.placement.resolve' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postTimecardResolveForJobListError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'timecard/finalize',
    type: postTimeCardFinalize.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postTimeCardFinalizeProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postTimeCardFinalizeError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'timecard/unfinalize',
    type: postTimeCardUnFinalize.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postTimeCardUnFinalizeProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postTimeCardUnFinalizeError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'timecard/approve',
    type: postPlacementApproval.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postPlacementApprovalProcessed(payload));
        dispatch(showSnackbar({ message: 'success.placement.approve' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postPlacementApprovalError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'timecard/resolve',
    type: postPlacementResolve.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postPlacementResolveProcessed(payload));
        dispatch(showSnackbar({ message: 'success.placement.resolve' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postPlacementResolveError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'timecard/finalize',
    type: postPlacementFinalize.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postPlacementFinalizeProcessed(payload));
        dispatch(showSnackbar({ message: 'success.placement.finalize' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postPlacementFinalizeError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'timecard/unfinalize',
    type: postPlacementUnFinalize.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(postPlacementUnFinalizeProcessed(payload));
        dispatch(showSnackbar({ message: 'success.placement.unfinalize' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postPlacementUnFinalizeError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'get-next-engagements',
    type: getCandidatesJobs.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCandidatesJobsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCandidatesJobsError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post', // Candidate Dashboard
    path: 'workable-groups',
    type: getFeaturedJobs.type,
    successCallback: (payload, dispatch) => {
      dispatch(getFeaturedJobsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getFeaturedJobsError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'workable-groups',
    type: getIndustryJobs.type,
    successCallback: (payload, dispatch) => {
      dispatch(getIndustryJobsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getIndustryJobsError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post', // Candidate Dashboard
    path: 'workable-groups',
    type: getInterestJobs.type,
    successCallback: (payload, dispatch) => {
      dispatch(getInterestJobsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getInterestJobsError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'timecards/read',
    type: getMyTimeThisWeek.type,
    successCallback: (payload, dispatch) => {
      dispatch(getMyTimeThisWeekProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getMyTimeThisWeekError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'skills/read',
    type: getSkills.type,
    successCallback: (payload, dispatch) => {
      dispatch(getSkillsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getSkillsError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'skills/read',
    type: getSkillsForJobInvite.type,
    successCallback: (payload, dispatch) => {
      dispatch(getSkillsForJobInviteProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getSkillsForJobInviteError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'skills/read',
    type: getSkillsForJobSignUp.type,
    successCallback: (payload, dispatch) => {
      dispatch(getSkillsForJobSignUpProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getSkillsForJobSignUpError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'job-orders/invite/respond',
    type: postInviteRespond.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(showSnackbar({ message: 'success.joborder.invite.respond' }));
        dispatch(postInviteRespondProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: postInviteRespondError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'certifications/read',
    type: getCerts.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCertsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getCertsError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'skills',
    type: sendNewSkillToBackend.type,
    successCallback: (payload, dispatch) => {
      dispatch(sendNewSkillToBackendProcessed(payload));
      dispatch(showSnackbar({ message: 'success.skill.add' }));

      dispatch(getSkills());
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: sendNewSkillToBackendError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'delete',
    path: 'skills',
    type: deleteSkill.type,
    successCallback: (payload, dispatch) => {
      dispatch(getSkills());
      dispatch(deleteSkillProcessed(payload));
      dispatch(showSnackbar({ message: 'delete.skill' }));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: deleteSkillError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'delete',
    path: 'certifications',
    type: deleteCert.type,
    successCallback: (payload, dispatch) => {
      dispatch(getCerts());
      dispatch(deleteCertProcessed(payload));
      dispatch(showSnackbar({ message: 'delete.cert' }));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: deleteCertError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'metrics/fill-ratio',
    type: getFillRatio.type,
    successCallback: (payload, dispatch) => {
      dispatch(getFillRatioProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getFillRatioError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'metrics/employee-retention',
    type: getEmployeeRetention.type,
    successCallback: (payload, dispatch) => {
      dispatch(getEmployeeRetentionProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getEmployeeRetentionError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'metrics/user-count-by-role',
    type: getUserCountByRole.type,
    successCallback: (payload, dispatch) => {
      dispatch(getUserCountByRoleProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getUserCountByRoleError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'job-orders/read',
    type: getJobsEntered.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobsEnteredProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getJobsEnteredError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'job-orders/read',
    type: getJobsCompleted.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobsCompletedProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getJobsCompletedError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'metrics/jobs-entered-by-role-by-date',
    type: getTimeBasedJobOrderNumbers.type,
    successCallback: (payload, dispatch) => {
      dispatch(getTimeBasedJobOrderNumbersProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getTimeBasedJobOrderNumbersError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'metrics/job-orders/revenue',
    type: getJobOrderRevenue.type,
    successCallback: (payload, dispatch) => {
      dispatch(getJobOrderRevenueProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getJobOrderRevenueError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'metrics/employees-hires', // FIXME: Derya check this once endpoint is ready
    type: getEmployeesHired.type,
    successCallback: (payload, dispatch) => {
      dispatch(getEmployeesHiredProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getEmployeesHiredError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'metrics/dnrs', // FIXME: Derya check this once endpoint is ready
    type: getDnrs.type,
    successCallback: (payload, dispatch) => {
      dispatch(getDnrsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getDnrsError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'metrics/dnas', // FIXME: Derya check this once endpoint is ready
    type: getDnas.type,
    successCallback: (payload, dispatch) => {
      dispatch(getDnasProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getDnasError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'metrics/overall-attendance',
    type: getOverallAttendance.type,
    successCallback: (payload, dispatch) => {
      dispatch(getOverallAttendanceProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getOverallAttendanceError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post',
    path: 'metrics/do-not-return-breakdown',
    type: getDoNotReturnBreakdown.type,
    successCallback: (payload, dispatch) => {
      dispatch(getDoNotReturnBreakdownProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getDoNotReturnBreakdownError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'job-orders/invite',
    type: inviteEmployee.type,
    successCallback: (payload, dispatch) => {
      dispatch(showSnackbar({ message: 'success.jobOrder.invite' }));
      dispatch(inviteEmployeeProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: inviteEmployeeError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'corporations/departments/delete',
    type: deleteDepartment.type,
    successCallback: (payload, dispatch) => {
      dispatch(showSnackbar({ message: 'success.department.delete' }));
      dispatch(deleteDepartmentProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: deleteDepartmentError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'corporations/departments/read',
    type: getDepartments.type,
    successCallback: (payload, dispatch) => {
      dispatch(getDepartmentsProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: getDepartmentsError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'corporations/departments',
    type: createDepartment.type,
    successCallback: (payload, dispatch) => {
      dispatch(showSnackbar({ message: 'success.department.created' }));
      dispatch(createDepartmentProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: createDepartmentError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'patch',
    path: 'corporations/departments',
    type: editDepartment.type,
    successCallback: (payload, dispatch) => {
      dispatch(showSnackbar({ message: 'success.department.update' }));
      dispatch(editDepartmentProcessed(payload));
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: editDepartmentError,
        responsePayload: payload,
      });
    },
  },

  {
    http: 'post', // Update placement check in or out
    path: 'placement/check-in',
    type: updatePlacementCheckInStatus.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(updatePlacementCheckInStatusProcessed(payload));
        dispatch(showSnackbar({ message: 'success.placement.checkIn.update' }));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: updatePlacementCheckInStatusError,
        responsePayload: payload,
      });
    },
  },
  {
    http: 'post',
    path: 'send-job-offer',
    type: sendInviteOrJobOffer.type,
    successCallback: (payload, dispatch) => {
      batch(() => {
        dispatch(showSnackbar('jobDetails.invite.success'));
        dispatch(sendInviteOrJobOfferProcessed(payload));
      });
    },
    errorCallback: (payload, dispatch) => {
      dispatchErrors({
        dispatch,
        errorHandler: sendInviteOrJobOfferError,
        responsePayload: payload,
      });
    },
  },
];

export default API;
