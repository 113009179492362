import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Typography } from '@mui/material';

import DeletePlacementDrawer from '../../../../../main-deprecated/views/jobs/jobList/detail/SubComponents/DeletePlacementDrawer';
import ManageUserDrawer from '../../../../../main-deprecated/views/jobs/jobList/detail/SubComponents/ManageUserDrawer';
import WaeButton, { BUTTON_VARIANT } from '../../../../components/Button';
import InviteCandidateModal from '../../../../components/InviteCandidateModal/InviteCandidateModal';
import selectUser from '../../../../store/selectors/appSelector';
import { PRIMARY_COLOR } from '../../../../theme/colorConstants';
import { epochToDateInReadableFormat, epochToDayOfWeek } from '../../../../utils';
import {
  addEmployeeToPlacement,
  approveOrRejectPlacement,
  cancelPlacement,
  inviteEmployee,
  removeUserFromGroup,
  revokeJobInvitations,
  sendInviteOrJobOffer,
  updateJobRosterEmployee,
} from '../../jobOrdersReducer';

import ActiveEmployeeRosterCard from './EmployeeRosterCards/ActiveEmployeeRosterCard';
import CancelledEmployeeRosterCard from './EmployeeRosterCards/CancelledEmployeeRosterCard';
import InviteEmployeeRosterCard from './EmployeeRosterCards/InviteEmployeeRosterCard';
import PendingEmployeeRosterCard from './EmployeeRosterCards/PendingEmployeeRosterCard';
import ReservedEmployeeRosterCard from './EmployeeRosterCards/ReservedEmployeeRosterCard';
import RosterBlock from './RosterBlock';

const RosterPanel = ({ jobOrder }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const intl = useIntl();
  const { numOpenings, numFilled } = jobOrder;
  const inviteNumber = Math.max(numOpenings - numFilled, 0);
  const activeEmployees = jobOrder.roster?.active?.slice(0, Math.min(numOpenings, numFilled)) || [];
  const reserveList = numFilled > numOpenings ? jobOrder.roster.active.slice(numOpenings) : [];

  const [selectedPlacementToManage, setSelectedPlacementToManage] = useState(null);
  const [isUserManageDrawerOpen, setIsUserManageDrawerOpen] = useState(false);
  const [selectedPlacementToCancel, setSelectedPlacementToCancel] = useState(null);
  const [isDeletePlacementDrawerOpen, setIsDeletePlacementDrawerOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  useEffect(() => {
    if (selectedPlacementToManage !== null) {
      setIsUserManageDrawerOpen(true);
    }
    if (selectedPlacementToCancel !== null) {
      setIsDeletePlacementDrawerOpen(true);
    }
  }, [selectedPlacementToManage, selectedPlacementToCancel]);

  const handleCandidateUpdate = useCallback(
    (employeeData) => {
      dispatch(updateJobRosterEmployee(employeeData));
    },
    [dispatch]
  );

  const handleCancelPlacement = useCallback(
    (placementId) => {
      dispatch(cancelPlacement({ id: placementId }));
    },
    [dispatch]
  );

  const handleCancelPlacementEntireGroup = useCallback(
    (placement) => {
      dispatch(
        removeUserFromGroup({
          candidate: placement?.employeeId,
          extensionGroup: jobOrder?.extensionGroup?.id,
        })
      );
    },
    [dispatch, jobOrder?.extensionGroup?.id]
  );

  const handleOnCloseUserManageDrawer = useCallback(() => {
    setSelectedPlacementToManage(null);
    setIsUserManageDrawerOpen(false);
  }, []);

  const handleOnCloseDeletePlacement = useCallback(() => {
    setSelectedPlacementToCancel(null);
    setIsDeletePlacementDrawerOpen(false);
  }, []);

  const handleRevokeInvite = useCallback(
    (placementId) => {
      dispatch(revokeJobInvitations({ placement: placementId }));
    },
    [dispatch]
  );

  const handleResendInvite = useCallback(
    (candidateId) => {
      dispatch(
        inviteEmployee({
          jobOrder: jobOrder.id,
          candidates: [candidateId],
        })
      );
    },
    [dispatch, jobOrder]
  );

  const handleAcceptOrRejectInvite = useCallback(
    (placementId, isApproved) => {
      dispatch(
        approveOrRejectPlacement({
          fields: {
            placement: placementId,
            approved: isApproved,
          },
        })
      );
    },
    [dispatch]
  );

  const handleSubmitInvite = useCallback(
    (candidateId) => {
      dispatch(
        addEmployeeToPlacement({
          fields: {
            jobOrders: [jobOrder?.id],
            candidate: candidateId,
          },
        })
      );
    },
    [dispatch, jobOrder]
  );

  const handleInviteOfferSubmit = useCallback(
    (candidateId) => {
      dispatch(sendInviteOrJobOffer({ jobOrder: jobOrder?.id, candidates: [candidateId] }));
    },
    [dispatch, jobOrder]
  );

  const activeEmployeesBlock = (
    <>
      {activeEmployees?.map((placement) => (
        <ActiveEmployeeRosterCard
          key={placement.id}
          placement={placement}
          isOngoingStatus={jobOrder?.extensionGroup && placement.isOnAutoPlacementList}
          onManageCandidate={() => setSelectedPlacementToManage(placement)}
          onDeleteFromOne={() => setSelectedPlacementToCancel({ ...placement, isOneOpening: true })}
          onDeleteFromAll={() =>
            setSelectedPlacementToCancel({ ...placement, isOneOpening: false })
          }
          extensionGroupId={jobOrder?.extensionGroup?.id}
        />
      ))}
      {Array.from({ length: inviteNumber }, (_, index) => index).map((number) => (
        <InviteEmployeeRosterCard
          key={number}
          onSubmitInvite={handleInviteOfferSubmit}
          userRole={user?.role}
        />
      ))}
    </>
  );

  const reservedEmployeesBlock = reserveList?.map((placement) => (
    <ReservedEmployeeRosterCard
      key={placement.id}
      placement={placement}
      onManageCandidate={() => setSelectedPlacementToManage(placement)}
      onDeleteFromOne={() => setSelectedPlacementToCancel({ ...placement, isOneOpening: true })}
      onDeleteFromAll={() => setSelectedPlacementToCancel({ ...placement, isOneOpening: false })}
      extensionGroupId={jobOrder?.extensionGroup?.id}
    />
  ));

  const offeredCandidates =
    jobOrder?.extensionGroup?.offeredCandidates?.map((placement) => (
      <PendingEmployeeRosterCard
        key={placement.id}
        placement={placement}
        isInvite
        onRevokeOrAcceptInvite={() => handleRevokeInvite(placement.id)}
        onResendOrRejectInvite={() => handleResendInvite(placement.id)}
      />
    )) || [];

  const invites =
    jobOrder?.roster?.invite?.map((placement) => (
      <PendingEmployeeRosterCard
        key={placement.id}
        placement={placement}
        isInvite
        onRevokeOrAcceptInvite={() => handleRevokeInvite(placement.id)}
        onResendOrRejectInvite={() => handleResendInvite(placement.employeeId)}
      />
    )) || [];

  const applications =
    jobOrder?.roster?.application?.map((placement) => (
      <PendingEmployeeRosterCard
        key={placement.id}
        placement={placement}
        isInvite={false}
        onRevokeOrAcceptInvite={() => handleAcceptOrRejectInvite(placement.id, true)}
        onResendOrRejectInvite={() => handleAcceptOrRejectInvite(placement.id, false)}
      />
    )) || [];

  const pendingEmployeesBlock = [...offeredCandidates, ...invites, ...applications];

  const cancelledEmployeesBlock = jobOrder?.roster?.cancelled?.map((placement) => (
    <CancelledEmployeeRosterCard
      key={placement.id}
      placement={placement}
      onManageCandidate={() => setSelectedPlacementToManage(placement)}
    />
  ));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '24px',
            marginBottom: '48px',
          }}
        >
          <Typography sx={{ fontSize: 24, fontWeight: '700' }}>
            {epochToDayOfWeek(jobOrder.start)}
          </Typography>
          <Typography sx={{ fontSize: 24, fontWeight: '400' }}>
            {epochToDateInReadableFormat(jobOrder.start)}
          </Typography>
        </Box>
        {user.role === UserRole.ADMIN && (
          <WaeButton
            variant={BUTTON_VARIANT.DEFAULT}
            actionColor={PRIMARY_COLOR[70]}
            onClick={() => {
              setIsInviteModalOpen(!isInviteModalOpen);
            }}
          >
            {LanguageConverter('buttonText.addEmployees')}
          </WaeButton>
        )}
      </Box>

      <Box sx={{ flexDirection: 'column' }}>
        <RosterBlock
          key="roster"
          testId="active"
          rosterContent={activeEmployeesBlock}
          title={`${LanguageConverter('jobOrder.roster')} (${
            activeEmployees.length
          }/${numOpenings})`}
          inviteNumber={inviteNumber}
        />
        {reservedEmployeesBlock?.length > 0 && (
          <RosterBlock
            key="reserveList"
            testId="reserve"
            rosterContent={reservedEmployeesBlock}
            title={`${LanguageConverter('jobOrder.status.reserveList')} (${
              reservedEmployeesBlock.length
            })`}
          />
        )}
        {pendingEmployeesBlock?.length > 0 && (
          <RosterBlock
            key="pending"
            testId="pending"
            rosterContent={pendingEmployeesBlock}
            title={`${LanguageConverter('jobOrder.status.pending')} (${
              pendingEmployeesBlock.length
            })`}
          />
        )}
        {cancelledEmployeesBlock?.length > 0 && (
          <RosterBlock
            key="cancelled"
            testId="cancelled"
            rosterContent={cancelledEmployeesBlock}
            title={`${LanguageConverter('jobOrder.status.cancelled')} (${
              cancelledEmployeesBlock.length
            })`}
          />
        )}
      </Box>
      {selectedPlacementToManage && (
        <ManageUserDrawer
          candidateName={selectedPlacementToManage.employeeName}
          originalEmployerNote={selectedPlacementToManage?.employerNote}
          handleOnSave={handleCandidateUpdate}
          fav={selectedPlacementToManage?.isFavorite}
          placementId={selectedPlacementToManage?.id}
          isOpen={isUserManageDrawerOpen}
          onClose={handleOnCloseUserManageDrawer}
        />
      )}
      {selectedPlacementToCancel && (
        <DeletePlacementDrawer
          type="outlined"
          isOpen={isDeletePlacementDrawerOpen}
          onClose={handleOnCloseDeletePlacement}
          candidateName={selectedPlacementToCancel.employeeName}
          handleCancelPlacement={() =>
            !jobOrder?.extensionGroup?.id || selectedPlacementToCancel?.isOneOpening
              ? handleCancelPlacement(selectedPlacementToCancel.id)
              : handleCancelPlacementEntireGroup(selectedPlacementToCancel)
          }
          isOneOpening={selectedPlacementToCancel.isOneOpening}
        />
      )}
      {isInviteModalOpen && (
        <InviteCandidateModal
          isOpen={isInviteModalOpen}
          onClose={() => setIsInviteModalOpen(false)}
          onSubmit={handleSubmitInvite}
          header={intl.formatMessage({ id: 'job.create.inviteModal.header.single' })}
          submitButtonText={intl.formatMessage({ id: 'button.addEmployees' })}
          text={intl.formatMessage({ id: 'job.create.invitesEmployeeSelected' })}
          isMultiSelect={false}
        />
      )}
    </Box>
  );
};

RosterPanel.propTypes = {
  jobOrder: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number,
    numOpenings: PropTypes.number,
    numFilled: PropTypes.number,
    fillRatio: PropTypes.number,
    extensionGroup: PropTypes.shape({
      offeredCandidates: PropTypes.arrayOf({
        id: PropTypes.string,
        name: PropTypes.string,
        employeeName: PropTypes.string,
        employeeProfilePicture: PropTypes.string,
        employerNote: PropTypes.string,
        isInvite: PropTypes.bool,
        isFavorite: PropTypes.bool,
        reliabilityScore: PropTypes.number,
        placementId: PropTypes.string,
      }),
      id: PropTypes.string,
    }),
    roster: PropTypes.shape({
      active: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          employeeName: PropTypes.string,
          employeeProfilePicture: PropTypes.string,
          employerNote: PropTypes.string,
          isFavorite: PropTypes.bool,
          reliabilityScore: PropTypes.number,
          isOnAutoPlacementList: PropTypes.string,
          placementId: PropTypes.string,
        })
      ),
      cancelled: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          employeeName: PropTypes.string,
          employeeProfilePicture: PropTypes.string,
          employerNote: PropTypes.string,
          isFavorite: PropTypes.bool,
          cancelledDate: PropTypes.number,
          cancelledWithin24Hours: PropTypes.bool,
          reliabilityScore: PropTypes.number,
        })
      ),
      invite: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          employeeName: PropTypes.string,
          employeeProfilePicture: PropTypes.string,
          employerNote: PropTypes.string,
          isInvite: PropTypes.bool,
          isFavorite: PropTypes.bool,
          reliabilityScore: PropTypes.number,
          placementId: PropTypes.string,
        })
      ),
      application: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          employeeName: PropTypes.string,
          employeeProfilePicture: PropTypes.string,
          employerNote: PropTypes.string,
          isInvite: PropTypes.bool,
          isFavorite: PropTypes.bool,
          reliabilityScore: PropTypes.number,
          placementId: PropTypes.string,
        })
      ),
    }),
  }),
};

export default RosterPanel;
